
import { GoogleServer } from '../../axios';
import Utils from '../../utils/Utils';
import HelperComponent from './HelperComponent';

/** Google Ads 소재 */
export default class AdHelper extends HelperComponent{
    static path="/media/ad"; //기본 Path
    static stat='/performance/ad';    //실적데이터
    static performances='/performances/ad';    //실적데이터
    static simple="/media/simple-ad"; //simple(combo) Path
    static downloadPath='/api/kakaomoment/xlsxdnld/ad';

    //소재 목록조회(광고그룹)
    public static getByAdgroup(adgroupId:string){
        return Utils.apiRequestValidation({}) || GoogleServer.get(`/api/google/ad/by-adgroup/${adgroupId}`,{headers:{useAccount:true}});
    }
}