import axios, { AxiosRequestConfig } from "axios";
import React from 'react';
import { UserContextModel } from '../../../contexts/UserContext';
import { GoogleServer } from '../../axios';
import UserSelect from '../../helpers/UserSelect';
import Utils from '../../utils/Utils';
import { ExplorerDetailReqType, ExplorerReqType, ExplorerResResultType } from '../models/google/ExplorerTypes';
import { StatReportResType, StatReportResultType } from '../models/google/StatReportType';
import { RequestFilterType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';
import { Service } from '../Service';
import AdGroupHelper from './AdGroupHelper';
import AdHelper from './AdHelper';
import CampaignHelper from './CampaignHelper';
import DashboardHelper from './DashboardHelper';
import DownloadHelper from './DownloadHelper';
import FileDownloadHelper from './FileDownloadHelper';
import LabelHelper from './LabelHelper';

/** Google Ads API 서비스 */
export default class GoogleService extends Service{
    public static common = class{
        public static getDateRange(range?:{start:Date, end:Date}):{start:Date, end:Date}{
            const dateRange = UserSelect.dateRange.get();
            const start = (range?.start || new Date(dateRange?.startDate || new Date().addDays(-15)));
            const end = (range?.end || new Date(dateRange?.endDate || new Date().addDays(-1)));
            return  {start, end};
        }
    }

    public static explorer<T=any>(level:string, search?:string, from?:number, size?:number){
        const dateRange = UserSelect.dateRange.get();
        // const start = new Date().addDays(-15).format('yyyyMMdd');
        // const end = new Date().addDays(-1).format('yyyyMMdd');
        const start = new Date(dateRange?.startDate || new Date().addDays(-15)).format('yyyyMMdd');
        const end = new Date(dateRange?.endDate || new Date().addDays(-1)).format('yyyyMMdd');

        const params:ExplorerReqType = {
            // startDate: dateRange?.startDate?.replaceAll("-", "") || start,   // "20230501",
            // endDate: dateRange?.endDate?.replaceAll("-", "") || end,      //"20230530",
            startDate: start,   // "20230501",
            endDate: end,      //"20230530",
            searchWord: search || '',
            from: from || 0,
            size: size || 20,
        };
        const config:AxiosRequestConfig = { params:params, headers:{useAccount:true} };
        const path = `/explorer/${level}`;
        return Utils.apiRequestValidation({}) || GoogleServer.get<MediaResponseType<ExplorerResResultType<T>>>(path, config);
    }

    public static performance(level:string, id:string, range?:{start:Date, end:Date}, deviceTypes?:string[]){
        const dateRange = UserSelect.dateRange.get();
        const deviceType:string[] = deviceTypes || UserSelect.deviceType.get() || ['P','M'];
        // const start = (range?.start || new Date().addDays(-15)).format('yyyyMMdd');
        // const end = (range?.end || new Date().addDays(-1)).format('yyyyMMdd');

        const params:ExplorerDetailReqType = {
            deviceTypes: deviceType,
            startDate: new Date(range?.start || dateRange?.startDate || new Date().addDays(-15)).format('yyyyMMdd'),   // "20230501",
            endDate: new Date(range?.end || dateRange?.endDate || new Date().addDays(-1)).format('yyyyMMdd'),      //"20230530",
        };
        const config:AxiosRequestConfig = { params:params, headers:{useAccount:true} };
        const path = `/performance/${level}/${id}`;
        return Utils.apiRequestValidation({}) || GoogleServer.get<MediaResponseType<StatReportResultType<StatReportResType>>>(path, config);
    }


    //// 공통 모듈 ////
    // 다운로드 알럿창 관리용
    public static downloadAlert(userContext:UserContextModel, onClick:React.MouseEventHandler){
        const valid = Utils.apiRequestValidation({}); if(valid){ return valid; }
        userContext.modalMessage.add({
            title:"다운로드",
            body:"검색결과 또는 선택한 항목을 다운로드 받으시겠습니까?",
            ok:{click:onClick},
            cancel:true,
        });
    }

    /**
     * S3URL을 이용하여 원하는 파일명으로 다운로드 합니다.
     * @param url S3 URL
     * @param filename : 다운로드시 생성될 파일명, 파일명을 지정하지 않는 경우 blob을 사용하지 않고 바로 새창열기를 통해 다운로드 시도합니다.
     */
    public static S3downloadReName(url:string, filename?:string){
        const server = axios.create();
        server.get(url).then((res)=>{
            //파일명을 지정하지 않는 경우 바로 다운로드
            if(!filename){
                window.open(url, '_blank');
            }else{
                // // 응답 헤더에서 response-content-disposition 값을 읽음
                // const contentDisposition = res.headers['response-content-disposition'];

                // // filename 속성의 값을 추출하기 위한 정규 표현식
                // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                
                // // 정규 표현식을 사용하여 filename 값을 추출
                // const matches = filenameRegex.exec(contentDisposition);
                
                // if (matches != null && matches[1]) {
                //     // filename 값이 존재할 경우 공백을 제거하고 출력
                //     const filename = matches[1].replace(/['"]/g, '');
                //     console.log('파일 이름:', filename);
                // } else {
                //     console.log('파일 이름을 찾을 수 없습니다.');
                // }

                const blob = new Blob([res.data]);
                // Blob을 파일로 다운로드
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename; // 새 파일 이름 설정
                link.click();
            }
        })
    }

    //삭제 공통
    public static delete(filters:RequestFilterType[], url:string) {
        return Utils.apiRequestValidation({}) || GoogleServer.delete(url,{data:{searchFilter:filters}, headers:{useAccount:true}});
    }

    //////// Class /////////
    public static campaign      = CampaignHelper;
    public static adgroup       = AdGroupHelper;
    public static ad            = AdHelper;
    public static dashboard     = DashboardHelper;
    public static filedownload  = FileDownloadHelper;
    public static download      = DownloadHelper;
    public static label         = LabelHelper;
    
}
