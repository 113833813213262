import { Axios, KakaoServer } from '../../axios';
import Utils from '../../utils/Utils';
import { LabelResType, LabelSetPutRawType, LabelSetPutType, LabelUnsetPutType } from '../models/LabelTypes';
import { MediaResponseType } from '../models/ResponseTypes';
import HelperComponent from './HelperComponent';

/** 네이버 레이블 Helper */
export default class LabelHelper extends HelperComponent{
    static path='/label';         //기본 Path
    static simple='/simple-label';

    /** 기본 레이블 최초 생성 */
    static create(){
        return Utils.apiRequestValidation({}) || KakaoServer.post<MediaResponseType<LabelResType>>(this.path, {}, {headers:{useAccount:true} });
    }

    /** 레이블 연결정보 초기화 */
    public static reset(id:number){
        return Utils.apiRequestValidation({}) || KakaoServer.delete(this.path, {params:{labelId:id}, headers:{useAccount:true}});
    }

    /** 조회한 대상 레이블 적용 */
    public static set(data:LabelSetPutType){
        const body:LabelSetPutRawType = {
            labelIds: data.labelIds,
            entityType: data.entityType,
            searchForm:{
                filters: Axios.filterConvert(data.filters || []),
            }
        }
        return Utils.apiRequestValidation({}) || KakaoServer.put(`${this.path}/set`, body, {headers:{useAccount:true}});
    }

    /** 조회한 대상 레이블 제거 */
    public static unset(body:LabelUnsetPutType){
        return Utils.apiRequestValidation({}) || KakaoServer.put(`${this.path}/unset`, body, {headers:{useAccount:true}});
    }
}

