/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import Config from '../../../../../../app/common/conf/Config';
import { TreeResDataType } from '../../../../../../app/common/services/models/ResponseTypes';
import { AdGroupSimpleResType } from '../../../../../../app/common/services/models/naver/AdGroupTypes';
import { CampaignSimpleResType } from '../../../../../../app/common/services/models/naver/CampaignTypes';
import { useUserContext } from '../../../../../../app/contexts/UserContext';
import NaverCode from '../../../../../../app/modules/code/NaverCode';
import Ellipsis from '../../../../../../app/modules/layer/Ellipsis';
import AdPloSearch from '../../../../../../app/pages/components/input/AdPloSearch';
import { SidebarTreeItem } from '../SidebarTreeItem';
import { SidebarTreeTitle } from '../SidebarTreeTitle';
import styles from '../style.module.scss';

const SidebarMenuMainNOSPTree = () => {
    const [search, setSearch] = useState<string>("");
    const userContext =useUserContext();

    useEffect(()=>{
        if(Config.app.TreeMode.enable){
            userContext.tree.naver.set({powerlink:[], shopping:[]}); //잔상방지
            userContext.modalLoading.show();
            userContext.tree.naver.load()
            .finally(()=>{ userContext.modalLoading.hide();});
        }
    // eslint-disable-next-line
    }, [userContext.userSelect.accountState?.account]);

    
    const basePath:string = '/pages/nosp/tree';
    const data = userContext.tree.naver.data.powerlink.filter((v)=>!search || v.data.campaignName.indexOf(search) > -1);
    return <>
        <SidebarTreeTitle
            key={NaverCode.data.type.campaign.WEB_SITE.value}
            to={`${basePath}`}
            icon='/media/icons/duotune/general/gen022.svg'
            title='NAVER 보장형 DA'
        >
            <AdPloSearch placeholder='캠페인 검색' onChange={(v)=>{ setSearch(v.target.value) } }/>
            <div className='fs-7 mt-2' style={{color:"#9D9DA6"}}>캠페인 : {data.length.addComma()}</div>
            {data.map((v:TreeResDataType<CampaignSimpleResType, AdGroupSimpleResType>)=>
                <SidebarMenuAdGroup key={v.data.campaignId} value={v.data} adgroup={v.children} to='/pages/nosp/tree/campaign'/>
            )}
        </SidebarTreeTitle>
        
    </>
}

export default SidebarMenuMainNOSPTree;


const SidebarMenuAdGroup:React.FC<{value:CampaignSimpleResType, adgroup:AdGroupSimpleResType[], to?:string}> = (props)=>{
    const [useData, setUseData] = useState<AdGroupSimpleResType[]>([]);
    useEffect(()=>{
        setUseData(props.adgroup);
    }, [props.adgroup])

    const border ='1px solid var(--bs-gray-600)';
    const to:string = `${props.to}/${props.value.campaignId}`;
    return <SidebarTreeTitle to={to}
        treeIcon={true}
        minimizeHidden={true}
        title={<>
            <IconUserLock userLock={props.value.userLock}/>
            <Ellipsis style={{width:'130px'}} title={props.value.campaignName}>{props.value.campaignName}</Ellipsis>
        </>}
    >
        <div className={clsx('scroll-y', styles.tree_item)}>
            {useData.map((v,index)=><SidebarTreeItem
                key={v.adGroupId} 
                className='p-1'
                to={`${to}/adline/${v.adGroupId}`}
                title={<>
                    <div className={styles.tree_item_bar}></div>
                    <IconUserLock userLock={v.userLock} style={{fontSize:'1.2em'}}/>
                    <Ellipsis style={{width:'160px'}} title={v.adGroupName}>{v.adGroupName}</Ellipsis>
                </>}
            />)}
        </div>
    </SidebarTreeTitle>
}

const IconUserLock:React.FC<{userLock?:boolean, style?:CSSProperties}> = (props)=><span 
    className={clsx('me-1', props.userLock ? 'text-danger' : 'text-success')}
    style={{fontSize:'1.5em', lineHeight:'100%', ...props.style}}
>●</span>;
