import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import DashboardWrapper from '../pages/dashboard/DashboardWrapper';

const PrivateRoutes = () => {
    const SettingsRoutes = lazy(() => import('../pages/settings/Routes'));
    const AdminRoutes = lazy(() => import('../pages/admin/Routes'));
    const NaverRoutes = lazy(() => import('../pages/naver/Routes'));
    const KakaoRoutes = lazy(() => import('../pages/kakao/Routes'));
    const NaloRoutes = lazy(() => import('../pages/nalo/Routes'));
    const ToolsRoutes = lazy(() => import('../pages/tools/Routes'));
    const RedirectRoutes = lazy(() => import('../pages/redirect/Routes'));

    const NospRoutes = lazy(() => import('../pages/nosp/Routes'));
    const GfaRoutes = lazy(() => import('../pages/gfa/Routes'));
    const KakaoMomentRoutes = lazy(() => import('../pages/kakaomoment/Routes'));
    const GoogleRoutes = lazy(() => import('../pages/google/Routes'));
    const MetaRoutes = lazy(() => import('../pages/meta/Routes'));

    return (<Routes>
        <Route element={<MasterLayout />}>
            {/* Profile Modules */}
            <Route path='pages/settings/*' element={<SuspensedView><SettingsRoutes /></SuspensedView>} />
            <Route path='pages/admin/*' element={<SuspensedView><AdminRoutes /></SuspensedView>} />

            <Route path='pages/naver/*' element={<SuspensedView><NaverRoutes /></SuspensedView> } />
            <Route path='pages/kakao/*' element={<SuspensedView><KakaoRoutes /></SuspensedView> } />

            <Route path='pages/nosp/*' element={<SuspensedView><NospRoutes /></SuspensedView> } />
            <Route path='pages/gfa/*' element={<SuspensedView><GfaRoutes /></SuspensedView> } />
            <Route path='pages/kakaomoment/*' element={<SuspensedView><KakaoMomentRoutes /></SuspensedView> } />
            <Route path='pages/google/*' element={<SuspensedView><GoogleRoutes /></SuspensedView> } />
            <Route path='pages/meta/*' element={<SuspensedView><MetaRoutes /></SuspensedView> } />

            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />

            {/* Pages */}
            <Route path='dashboard' element={<DashboardWrapper />} />
            {/* Lazy Modules */}

            <Route path='pages/nalo/*' element={<SuspensedView><NaloRoutes /></SuspensedView> } />
            <Route path='pages/tools/*' element={<SuspensedView><ToolsRoutes /></SuspensedView> } />

            {/* Redirect to notification page */}
            <Route path='pages/redirect/*' element={<SuspensedView><RedirectRoutes /></SuspensedView> } />

            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
    </Routes>)
}

export const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes };

