import { StatReportResKeys, StatReportResType } from "./StatReportType";

/**
 * GFA Ad Response Type
 */
export interface AdResType extends Omit<StatReportResType, 'key'>{
    accountGroupId: number;
    clientId: number;
    adId: string;
    adName: string;
    adType: string;
    adSetId: string;
    adSetName: string;
    campaignId: string;
    campaignName: string;
    campaignType: string;
    creativeId: string;
    imgUrl: string;
    createdTime: string;
}

/**
 * GFA Ad Response 컬럼 키
 */
export enum AdResKeys{
    accountGroupId = "accountGroupId",
    clientId = "clientId",
    adId = "adId",
    adName = "adName",
    adType = "adType",
    adSetId = "adSetId",
    adSetName = "adSetName",
    campaignId = "campaignId",
    campaignName = "campaignName",
    campaignType = "campaignType",
    creativeId = "creativeId",
    imgUrl = "imgUrl",
    createdTime = "createdTime",
}


export const AdKeys = {...AdResKeys, ...StatReportResKeys};