import clsx from "clsx";
import { CSSProperties, useRef } from "react";
import { ButtonProps, Dropdown } from "react-bootstrap";
import { DropdownMenuProps } from "react-bootstrap/esm/DropdownMenu";
import AdploButton from "../../components/buttons/AdploButton";
import styles from './style.module.scss';

export interface ButtonDropDownProps{
    className?:string;
    style?:CSSProperties;
    title?:string|React.ReactNode;
    children?:React.ReactNode;
    button?:ButtonProps;
    menu?:DropdownMenuProps;
    onSubmit?:()=>void;
    onCancel?:()=>void;
    submitTitle?:string;
    cancelTitle?:string;
    disabled?: boolean;
    onHide?:()=>void;
    onShow?:()=>void;
    customButton?: React.ReactNode;
    useCaret?: boolean;
}

export const ButtonDropDown:React.FC<ButtonDropDownProps> = (props)=>{
    const body = useRef<HTMLDivElement>(null);
    const btn = useRef<HTMLButtonElement>(null);
    const onSubmit = () => {
        body?.current?.click();
        props.onSubmit && props.onSubmit();
    }

    const onShowStatus = (show:boolean)=>{
        show ? (props.onShow && props.onShow()) : (props.onHide && props.onHide());
    }
    return <Dropdown ref={body} className={props.className} style={props.style} aria-disabled={props.disabled} onToggle={onShowStatus} >
        <Dropdown.Toggle ref={btn} id="Dropdown_Toggle_Custom" variant="light" {...props.button} disabled={props.disabled}
            className={clsx('border align-content-center', styles.toggle, props.button?.className)}>
            {props.title}
            {props.useCaret && <i className="bi bi-chevron-down ms-2" style={{marginRight:'-1em'}}/>}
        </Dropdown.Toggle>
        <div style={{position:'absolute'}}>
            <Dropdown.Menu {...props.menu}>
                {props.children}
                <div className='text-right'>
                    {props.customButton}
                    {props.onCancel && <AdploButton variant="secondary" className='me-1' onClick={()=>{
                        props.onCancel && props.onCancel();
                        btn.current?.click();
                    }}>{props.cancelTitle || '취소'}</AdploButton>}
                    {props.onSubmit && <AdploButton variant="primary" onClick={onSubmit}>{props.submitTitle || '적용'}</AdploButton>}
                </div>
            </Dropdown.Menu>
        </div>
    </Dropdown>
}
