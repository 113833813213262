import { KakaoMomentServer } from '../../axios';
import Utils from '../../utils/Utils';
import HelperComponent from './HelperComponent';

/** 카카오모먼트 소재 */
export default class CreativeHelper extends HelperComponent{
    static path="/media/creatives"; //기본 Path
    static stat='/performance/creatives';    //실적데이터
    static performances='/performances/creatives';    //실적데이터
    static simple="/media/simple-creatives"; //simple(combo) Path
    static downloadPath='/api/kakaomoment/xlsxdnld/creatives';

    //소재 목록조회(광고그룹)
    public static getByAdgroup(adgroupId:string){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get(`/api/kakaokeywordad/creative/by-adgroup/${adgroupId}`,{headers:{useAccount:true}});
    }
}