import HelperComponent from './HelperComponent';

/** 카카오모먼트 광고그룹 */
export default class AdGroupHelper extends HelperComponent{
    static path="/media/adgroup"; //기본 Path
    static onOff="/media/adgroup/on-off"; //onOff modify Path
    static simple="/media/simple-adgroup"; //simple(combo) Path
    static stat='/performance/adgroup';    //실적데이터
    static performances='/performances/adgroup';    //실적데이터
    static downloadPath='/api/kakaomoment/xlsxdnld/adgroups';
}
