import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useUserContext } from '../../app/contexts/UserContext'
import { reInitMenu } from '../helpers'
import { ThemeModeProvider } from '../partials'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { FooterWrapper } from './components/footer'
import { ScrollTop } from './components/scroll-top'
import { PageDataProvider } from './core'

const PopupLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  const userContext = useUserContext();

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <Outlet />
        </div>
        <FooterWrapper />

        {/* begin:: Drawers */}
        <RightToolbar />
        {/* end:: Drawers */}

        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { PopupLayout }

