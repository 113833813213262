/* eslint-disable react/jsx-no-target-blank */
// import React from 'react';
import { useIntl } from 'react-intl';
// import {KTSVG} from '../../../../helpers';
import Config from '../../../../../app/common/conf/Config';
import UserSelect from '../../../../../app/common/helpers/UserSelect';
import { AuthService } from '../../../../../app/common/services';
import NotificationCode from '../../../../../app/modules/code/NotificationCode';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import SidebarMenuMainGFA from './gfa/SidebarMenuMainGFA';
import SidebarMenuMainGoogleAds from './google/SidebarMenuMainGoogleAds';
import SidebarMenuMainKakao from './kakao/SidebarMenuMainKakao';
import SidebarMenuMainKakaoMoment from './kakaomoment/SidebarMenuMainKakaoMoment';
import SidebarMenuMainMeta from './meta/SidebarMenuMainMeta';
import SidebarMenuMainNaver from './naver/SidebarMenuMainNaver';
import SidebarMenuMainNOSP from './nosp/SidebarMenuMainNOSP';

const SidebarMenuMain = () => {
    const intl = useIntl();
    const account = UserSelect.account.get();
    const mediaType = account?.accountClient?.mediaType;
    const user = AuthService.storage.get();
    const isAdmin: boolean | undefined = user?.userInfo.authorities.includes('ROLE_IAM_ADMIN');

    const isNaver       = ['NAVER', 'A01M1001', 'LMT0001'].includes(mediaType || '');
    const isKakao       = ['KAKAO', 'A01M1002', 'LMT0002'].includes(mediaType || '');
    const isGFA         = ['NAVER_GFA',         'LMT2011'].includes(mediaType || '');
    const isNOSP        = ['NAVER_NOSP',        'LMT2012'].includes(mediaType || '');
    const isKakaoMoment = ['KAKAO_MOMENT',      'LMT2021'].includes(mediaType || '');
    const isMeta        = ['META',              'LMT2031'].includes(mediaType || '');
    const isGoogle      = ['GOOGLE',            'LMT2041'].includes(mediaType || '');
    // const isGoogle = ['GOOGLE','A01M1003'].includes(mediaType || '');
    // const isDevelMode = ['devel', 'local'].includes(Config.project.mode);
    const isLocalMode = ['local'].includes(Config.project.mode);

    return (
        <>
            {/* <SidebarMenuItem to='/dashboard' icon='/media/icons/duotune/art/art002.svg' title={intl.formatMessage({id: 'MENU.DASHBOARD'})} /> */}
            {false && (
                <SidebarMenuItemWithSub
                    to='/pages/settings'
                    title='설정'
                    icon='/media/icons/duotune/coding/cod001.svg'
                >
                    <SidebarMenuItem
                        to='/pages/settings/profile'
                        title='마이페이지'
                        fontIcon='bi-person-gear'
                    />
                    <SidebarMenuItem
                        to='/pages/settings/settings'
                        title='비밀번호 변경'
                        fontIcon='bi-key'
                    />
                    {/* <SidebarMenuItem to='/pages/settings/builder' icon='/media/icons/duotune/general/gen019.svg' title='Layout Builder' /> */}
                </SidebarMenuItemWithSub>
            )}

            {/********* SA ********/}
            {isNaver && (<><span className='text-muted'><hr/></span><SidebarMenuMainNaver/></>)}
            {isKakao && (<><span className='text-muted'><hr/></span><SidebarMenuMainKakao/></>)}

            {
                Config.app.DA.enable && 
                /******* DA *******/
                <>
                    {isNOSP         && (<><span className='text-muted'><hr/></span><SidebarMenuMainNOSP /></>)}
                    {isGFA          && (<><span className='text-muted'><hr/></span><SidebarMenuMainGFA /></>)}
                    {isKakaoMoment  && (<><span className='text-muted'><hr/></span><SidebarMenuMainKakaoMoment /></>)}
                    {isGoogle       && (<><span className='text-muted'><hr/></span><SidebarMenuMainGoogleAds /></>)}
                    {isMeta         && (<><span className='text-muted'><hr/></span><SidebarMenuMainMeta /></>)}
                </>
            }

            {isAdmin && (
                <>
                    <span className='text-muted'><hr /></span>
                    <SidebarMenuItemWithSub
                        to='/pages/admin'
                        title='관리'
                        keenIcon={NotificationCode.data.type.CNM0007.icon}
                    >
                        <SidebarMenuItem
                            to='/pages/admin/accountGroup'
                            title='광고주 관리'
                            fontIcon='bi-person-lines-fill'
                        />
                        <SidebarMenuItem
                            to='/pages/admin/account'
                            title='자산 관리'
                            fontIcon='bi-key'
                        />
                        <SidebarMenuItem
                            to='/pages/admin/user'
                            title='회원 관리'
                            fontIcon='bi-person-gear'
                        />
                        {Config.app.NoticeBanner.enable && <SidebarMenuItem
                            to='/pages/admin/banner'
                            title='배너 관리'
                            fontIcon='bi-card-image'
                        />}
                        {/* <SidebarMenuItem to='/pages/admin/site' title='사이트 관리' fontIcon='bi-globe' /> */}
                    </SidebarMenuItemWithSub>
                </>
            )}

            {isLocalMode && (
                <>
                    <SidebarMenuItem
                        to='/pages/nalo'
                        keenIcon={NotificationCode.data.type.CNM0010.icon}
                        title={'NALO'}
                    />
                    <SidebarMenuItemWithSub
                        to='/pages/tools'
                        title='Tools'
                        icon='/media/icons/duotune/abstract/abs025.svg'
                    >
                        <SidebarMenuItemWithSub
                            to='/pages/tools/info'
                            title='기본정보'
                            fontIcon='bi bi-gear'
                        >
                            <SidebarMenuItem
                                to='/pages/tools/info/user'
                                title='사용자 관리'
                                fontIcon='bi-person-gear'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/info/auth'
                                title='권한 관리'
                                fontIcon='bi bi-shield-check'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/info/menu'
                                title='메뉴 관리'
                                fontIcon='bi bi-card-list'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/info/code'
                                title='코드정보'
                                fontIcon='bi bi-list-ul'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/info/adbase'
                                title='[AdBase]사용자'
                                fontIcon='bi bi-person-up'
                            />
                        </SidebarMenuItemWithSub>
                        <SidebarMenuItemWithSub
                            to='/pages/tools/naver'
                            title='Naver'
                            fontIcon='bi bi-cloud-arrow-down'
                        >
                            <SidebarMenuItem
                                to='/pages/tools/naver/relation'
                                title='키워드정보'
                                fontIcon='bi bi-info-square'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/naver/crawler'
                                title='키워드정보 자동수집'
                                fontIcon='bi bi-cloud-arrow-down'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/naver/shopdown'
                                title='쇼핑검색 가격비교 매칭'
                                fontIcon='bi bi-cloud-arrow-down'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/naver/adlist'
                                title='광고현황 조회'
                                fontIcon='bi bi-badge-ad'
                            />
                            <SidebarMenuItem
                                to='/pages/tools/naver/image'
                                title='이미지 필터'
                                fontIcon='bi bi-filter-square'
                            />
                        </SidebarMenuItemWithSub>
                        <SidebarMenuItem
                            to='/pages/tools/tbaselist'
                            title='TBase Tag'
                            fontIcon='bi bi-file-earmark-text'
                        />
                        <SidebarMenuItem
                            to='/pages/tools/tbaseregister'
                            title='TBase 태그발급요청'
                            fontIcon='bi bi-pencil-square'
                        />
                        <SidebarMenuItem
                            to='/pages/tools/deadlink'
                            title='데드링크'
                            fontIcon='bi bi-clipboard-check'
                        />
                        <SidebarMenuItem
                            to='/pages/tools/encoder'
                            title='엔코더(Text)'
                            fontIcon='bi bi-braces-asterisk'
                        />
                        <SidebarMenuItem
                            to='/pages/tools/urlparser'
                            title='URL파싱'
                            fontIcon='bi bi-vr'
                        />
                        <SidebarMenuItem
                            to='/pages/tools/urlcreate'
                            title='URL생성기'
                            fontIcon='bi bi-link-45deg'
                        />
                    </SidebarMenuItemWithSub>
                </>
            )}
            {/* <SidebarMenuItemWithSub to='/pages/sample' title='컨포넌트 개발' icon='/media/icons/duotune/general/gen025.svg'>
                <SidebarMenuItem to='/pages/sample/ahk007' title='안형근 작업창' hasBullet={true} />
            </SidebarMenuItemWithSub> */}
        </>
    );
};

export { SidebarMenuMain };

