import {NaverServer} from '../../axios';
import Utils from '../../utils/Utils';
import {HistoryPostType} from '../models/naver/HistoryTypes';
import NaverHelperComponent from './NaverHelperComponent';

/** 네이버 관리이력 및 대량업로드 Helper */
export default class HistoryHelper extends NaverHelperComponent {
    static path = '/media/task-status'; //기본 Path
    public static extra(taskId: number, taskExtraId: number) {
        return (
            Utils.apiRequestValidation({}) ||
            NaverServer.get<any>(
                `/media/task-status/${taskId}/task-extra/${taskExtraId}/download-path`,
                {headers: {useAccount: true}}
            )
        );
    }

    public static extraWithAccountId(
        taskId: number,
        taskExtraId: number,
        accountGroupId: number,
        clientId: string
    ) {
        return (
            Utils.apiRequestValidation({useAccount: false}) ||
            NaverServer.get<any>(
                `account-group-id/${accountGroupId}/client-id/${clientId}/media/task-status/${taskId}/task-extra/${taskExtraId}/download-path`
            )
        );
    }

    /** 파일을 S3에 업로드합니다.  */
    public static execute(uploadId: number) {
        return (
            Utils.apiRequestValidation({}) ||
            NaverServer.put<any>(`/media/upload-files/${uploadId}/execute`, undefined, {
                headers: {useAccount: true},
            })
        );
    }

    /** 파일을 S3에 업로드합니다.  */
    public static fileUpload(data: HistoryPostType) {
        const url = new URLSearchParams(Object.entries(data));
        return (
            Utils.apiRequestValidation({}) ||
            NaverServer.post<any>(`/media/upload-files?${url}`, undefined, {
                headers: {useAccount: true},
            })
        );
    }
}
