import { GoogleServer } from '../../axios';
import Utils from '../../utils/Utils';
import HelperComponent from './HelperComponent';

/** Google Ads 캠페인 */
export default class CampaignHelper extends HelperComponent{
    static path="/media/campaigns"; //기본 Path
    static onOff="/media/campaigns/on-off"; //onOff modify Path
    static simple="/media/simple-campaigns"; //simple(combo) Path
    static stat='/performance/campaigns';    //실적데이터
    static performances='/performances/campaigns';    //실적데이터
    static downloadPath='/api/kakaomoment/xlsxdnld/campaigns';
    //계정에 대한 캠페인 등록 개수 조회
    public static getCount(){
        return Utils.apiRequestValidation({}) || GoogleServer.get(`/media/campaign/count`,{headers:{useAccount:true}});
    }
}
