import { OptionType } from "../../../modules/types/Types";
import Template from "../../common/conf/Templet";
import { AdminService } from "../../common/services";
import { CodeResType } from "../../common/services/models/admin/CodeTypes";
import NaverCode from "./NaverCode";

const STORAGE_ID = 'iam.admin.code';
interface PublicCodeStorageType{
    create_time:number;
    data:CodeResType[];
}
/*
code	CG1	CG2	 name	 description	 category	 useYn	 createdBy	 modifiedBy	 createdDatetime	 modifiedDatetime
CRC0001	C	RC	UI	요청타입 UI	CRC0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
CRC0002	C	RC	파일	요청타입 파일	CRC0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
CRC0003	C	RC	배치	요청타입 배치	CRC0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
CTS0001	C	TS	대기중	대기중	CTS0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
CTS0002	C	TS	작업중	작업중	CTS0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
CTS0003	C	TS	완료	완료	CTS0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
CTS0004	C	TS	문제 발생	작업 중 문제 발생	CTS0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0001	K	ET	카카오 검색광고 광고계정	카카오 검색광고 광고계정	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0002	K	ET	카카오 검색광고 비즈채널	카카오 검색광고 비즈채널	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0003	K	ET	카카오 검색광고 캠페인	카카오 검색광고 캠페인	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0004	K	ET	카카오 검색광고 광고그룹	카카오 검색광고 광고그룹	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0005	K	ET	카카오 검색광고 키워드	카카오 검색광고 키워드	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0006	K	ET	카카오 검색광고 소재	카카오 검색광고 소재	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0007	K	ET	카카오 검색광고 연결소재	카카오 검색광고 연결소재	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KET0008	K	ET	카카오 검색광고 이미지	카카오 검색광고 이미지	KET0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KLT0001	K	LT	Raw 데이터	Raw 데이터	KLT0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KLT0002	K	LT	수정	수정	KLT0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KLT0003	K	LT	수정(ON/OFF)	수정(ON/OFF)	KLT0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KLT0004	K	LT	삭제	삭제	KLT0000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT1001	K	TT	마스터 동기화	마스터 동기화	KTT1000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT1002	K	TT	실적 동기화	실적 동기화	KTT1000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT1003	K	TT	배치 동기화	배치 동기화	KTT1000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT1004	K	TT	배치 동기화 마스터	배치 동기화 마스터	KTT1000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT1005	K	TT	배치 동기화 실적	배치 동기화 실적	KTT1000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT2001	K	TT	생성	생성	KTT2000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT2002	K	TT	복사	복사	KTT2000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT2003	K	TT	수정	수정	KTT2000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT2004	K	TT	수정(ON/OFF)	수정(ON/OFF)	KTT2000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT2005	K	TT	수정(입찰가)	수정(입찰가)	KTT2000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
KTT2006	K	TT	삭제	삭제	KTT2000	YES	null	null	2023-07-05T14:20:50.396977+09:00	2023-07-05T14:20:50.396977+09:00
NBM0001	N	BM	전체 동기화	배치 전체 동기화	NBM0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBM0002	N	BM	변경분 동기화	배치 변경분 동기화	NBM0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBS0001	N	BS	준비	배치 준비	NBS0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBS0002	N	BS	실행중	배치 실행중	NBS0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBS0003	N	BS	완료	배치 완료	NBS0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBS0004	N	BS	작업오류	배치 작업 중 문제 발생	NBS0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBT0001	N	BT	마스터	배치 타입 마스터	NBT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBT0002	N	BT	실적	배치 타입 실적	NBT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBZ0001	N	BZ	동기화 실행중	배치 동기화 상태 실행 중	NBZ0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBZ0002	N	BZ	동기화 완료	배치 동기화 상태 완료	NBZ0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NBZ0003	N	BZ	비어있음	배치 동기화 상태 비어있음	NBZ0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0001	N	ET	전체	네이버 검색광고 전체	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0002	N	ET	비즈채널	네이버 검색광고 비즈채널	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0003	N	ET	캠페인	네이버 검색광고 캠페인	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0004	N	ET	광고그룹	네이버 검색광고 광고그룹	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0005	N	ET	소재	네이버 검색광고 소재	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0006	N	ET	키워드	네이버 검색광고 키워드	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0007	N	ET	확장소재	네이버 검색광고 확장소재	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0008	N	ET	크리테리온	네이버 검색광고 크리테리온	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0009	N	ET	타겟	네이버 검색광고 타겟	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET0010	N	ET	제외키워드	네이버 검색광고 제외키워드	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NET9999	N	ET	모든계정	네이버 검색광고 모든계정	NET0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NPT0001	N	PT	파워링크	네이버 파워링크	NPT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NPT0002	N	PT	쇼핑검색	네이버 쇼핑검색	NPT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTE0001	N	TE	작업결과파일	작업 결과 파일	NTE0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTE0002	N	TE	작업결과메타파일	작업 결과 메타 파일	NTE0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0001	N	TT	동기화	동기화	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0002	N	TT	수정	수정	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0003	N	TT	등록	등록	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0004	N	TT	삭제	삭제	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0005	N	TT	복사	복사	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0006	N	TT	ON/OFF 변경	ON/OFF 변경	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
NTT0007	N	TT	입찰가 수정	입찰가 수정	NTT0000	YES	null	null	2023-07-05T14:36:48.225366+09:00	2023-07-05T14:36:48.225366+09:00
*/

export interface TaskOptionType extends Omit<OptionType, 'children'>{
    /** 파일Path */
    path?:string;
    /** 비활성화 */
    disabled?:boolean;
    /** 주의 문구 */
    warning?:string|React.ReactNode;
    children?:TaskOptionType[];
    /** 매체 타입코드 값 */
    code?:string;
}


class PublicCodeCore {
    private _data:CodeResType[] = [];

    public category = {
        /** 작업상태 */
        CTS0000:{value:'CTS0000', label:'작업상태'},
        /** 요청경로 */
        CRC0000:{value:'CRC0000', label:'요청경로'},
        /** 작업유형 */
        NTT0000:{value:'NTT0000', label:'작업유형'},
        KTT0000:{value:'KTT0000', label:'작업유형'},
        KTT1000:{value:'KTT1000', label:'작업유형1'},
        KTT2000:{value:'KTT2000', label:'작업유형2'},
        KLT0000:{value:'KLT0000', label:''},
        KET0000:{value:'KET0000', label:''},
        /** 관리대상 유형 */
        NET0000:{value:'NET0000', label:'관리대상 유형'},
        NBS0000:{value:'NBS0000', label:''},
        NBT0000:{value:'NBT0000', label:''},
        NBZ0000:{value:'NBZ0000', label:''},
        NBM0000:{value:'NBM0000', label:''},
        /** 상품형태 */
        NPT0000:{value:'NPT0000', label:''},
        NDT0000:{value:'NDT0000', label:'네이버 다운로드 타입'},
        KDT0000:{value:'KDT0000', label:'카카오 다운로드 타입'},
    }

    public options={
        role:{
            OWNER:{value:'OWNER', label:'Owner'},
            VIEWER:{value:'VIEWER', label:'Viewer'}
        },
    }

    /** 작업 요청 또는 템플릿 다운로드용 */
    public taskTypeTree = class{
        public static naver:Readonly<TaskOptionType[]> = [
            {
                value:'NPT0001',
                label:'파워링크',
                code: NaverCode.data.type.campaign.WEB_SITE.value,
                children:[
                    {
                        value:'NET0003',
                        label:'캠페인',
                        children:[
                            {value:'NTT0003', label:'등록', path: Template.naver.powerlink.campaign.create},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.powerlink.campaign.onOff},
                            {value:'NTT0002', label:'수정', path: Template.naver.powerlink.campaign.modify},
                        ],
                    },
                    {
                        value:'NET0004',
                        label:'광고그룹',
                        children:[
                            {value:'NTT0003', label:'등록', path: Template.naver.powerlink.adgroup.create},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.powerlink.adgroup.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.powerlink.adgroup.delete},
                            {value:'NTT0002', label:'수정', path: Template.naver.powerlink.adgroup.modify},
                            // {value:'NTT0005', label:'복사', disabled:true, warning:'추후 제공', },
                        ],
                    },
                    {
                        value:'NET0006',
                        label:'키워드',
                        children:[
                            {value:'NTT0003', label:'등록', path: Template.naver.powerlink.keyword.create},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.powerlink.keyword.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.powerlink.keyword.delete},
                            {value:'NTT0005', label:'복사', path: Template.naver.powerlink.keyword.copy},
                            {value:'NTT0002', label:'수정', path: Template.naver.powerlink.keyword.modify},
                        ],
                    },
                    {
                        value:'NET0005',
                        label:'소재',
                        children:[
                            {value:'NTT0003', label:'등록', path: Template.naver.powerlink.ad.create},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.powerlink.ad.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.powerlink.ad.delete},
                            {value:'NTT0005', label:'복사', path: Template.naver.powerlink.ad.copy},
                        ],
                    },
                    {
                        value:'NET0007',
                        label:'확장소재',
                        children:[
                            // {value:'NTT0003', label:'등록', disabled:true, path: Template.naver.powerlink.adExtension, warning:"추후 제공"},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.powerlink.adExtension.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.powerlink.adExtension.delete},
                            {value:'NTT0005', label:'복사', path: Template.naver.powerlink.adExtension.copy, warning:"파워링크의 확장소재 복사는 '추가제목, 추가설명, 홍보문구, 서브링크, 가격링크'만 가능합니다."},
                        ],
                    },

                ],
            },
            {
                value:'NPT0002',
                label:'쇼핑검색',
                code: NaverCode.data.type.campaign.SHOPPING.value,
                children:[
                    {
                        value:'NET0003',
                        label:'캠페인',
                        children:[
                            {value:'NTT0003', label:'등록', path: Template.naver.shopping.campaign.create},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.shopping.campaign.onOff},
                            {value:'NTT0002', label:'수정', path: Template.naver.shopping.campaign.modify},
                        ],
                    },
                    {
                        value:'NET0004',
                        label:'광고그룹',
                        children:[
                            {value:'NTT0003', label:'등록', path: Template.naver.shopping.adgroup.create, warning: "광고그룹 유형 중 '쇼핑몰 상품형'만 등록 가능합니다."},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.shopping.adgroup.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.shopping.adgroup.delete},
                            {value:'NTT0002', label:'수정', path: Template.naver.shopping.adgroup.modify, warning:"광고그룹 유형 중 '쇼핑몰 상품형'만 수정 가능합니다."},
                        ],
                    },
                    {
                        value:'NET0006',
                        label:'키워드',
                        children:[
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.shopping.keyword.onOff, warning:"쇼핑검색의 키워드 ON/OFF는 '쇼핑 브랜드형'만 가능합니다."},
                        ],
                    },

                    {
                        value:'NET0005',
                        label:'소재',
                        children:[
                            // {value:'NTT0003', label:'등록', path: Template.naver.shopping.ad.create, warning:"소재 유형 중 '쇼핑몰 상품형'만 등록 가능합니다."},
                            // {value:'NTT0002', label:'수정', path: Template.naver.shopping.ad.update, warning:"쇼핑검색의 소재의 입찰가 변경은 '쇼핑몰 상품형'만 가능합니다."},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.shopping.ad.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.shopping.ad.delete},
                            {value:'NTT0007', label:'수정(입찰가변경)', path: Template.naver.shopping.ad.bidAmount,},
                            {value:'NTT0005', label:'복사', path: Template.naver.shopping.ad.copy},
                        ],
                    },
                    {
                        value:'NET0007',
                        label:'확장소재',
                        children:[
                            // {value:'NTT0003', label:'등록', disabled:true, path: Template.naver.shopping.adExtension, warning:"추후 제공, 제공 확장소재 유형 : 추가홍보문구"},
                            {value:'NTT0006', label:'ON/OFF 변경', path: Template.naver.shopping.adExtension.onOff},
                            {value:'NTT0004', label:'삭제', path: Template.naver.shopping.adExtension.delete},
                            // {value:'NTT0005', label:'복사', path: Template.naver.shopping.adExtension},
                        ],
                    },

                ],
            },
        ];

        public static kakao:Readonly<TaskOptionType[]> = [
            {
                value:'KET0003',
                label:'캠페인',
                children:[
                    {value:'KTT2001', label:'생성', path: Template.kakao.campaign.create},
                    {value:'KTT2004', label:'ON/OFF 변경', path: Template.kakao.campaign.onOff},
                    {value:'KTT2003', label:'수정', path: Template.kakao.campaign.modify},
                ],
            },
            {
                value:'KET0004',
                label:'광고그룹',
                children:[
                    {value:'KTT2001', label:'생성', path: Template.kakao.adgroup.create, warning:"키워드확장 '설정' 선택 시 제외키워드 등록 기능은 제공 안 함 "},
                    {value:'KTT2004', label:'ON/OFF 변경', path: Template.kakao.adgroup.onOff},
                    {value:'KTT2006', label:'삭제', path: Template.kakao.adgroup.delete},
                    {value:'KTT2003', label:'수정', path: Template.kakao.adgroup.modify},
                    // {value:'KTT2002', label:'복사', disabled:true, path: Template.kakao.adgroup, warning:"추후 제공"},
                ],
            },
            {
                value:'KET0005',
                label:'키워드',
                children:[
                    {value:'KTT2001', label:'생성', path: Template.kakao.keyword.create},
                    {value:'KTT2004', label:'ON/OFF 변경', path: Template.kakao.keyword.onOff},
                    {value:'KTT2006', label:'삭제', path: Template.kakao.keyword.delete},
                    {value:'KTT2003', label:'수정', path: Template.kakao.keyword.modify},
                    {value:'KTT2002', label:'복사', path: Template.kakao.keyword.copy},
                ],
            },
            {
                value:'KET0006',
                label:'소재',
                children:[
                    {value:'KTT2001', label:'소재 연결', disabled:true, path: Template.kakao.creativeLink.create, warning:"소재와 광고그룹간의 연결정보를 등록합니다."},
                    // {value:'KTT2001', label:'등록 및 연결', disabled:true, path: Template.kakao.creative, warning:"제공 안 함"},
                    // {value:'KTT2004', label:'ON/OFF 변경', path: Template.kakao.creative, warning:"소재ID 삭제"},
                    {value:'KTT2006', label:'삭제', path: Template.kakao.creative.delete, warning:"소재연결ID가 삭제됨"},
                ],
            },
            {
                value:'KET0007',
                label:'소재연결',
                children:[
                    {value:'KTT2004', label:'ON/OFF 변경', path: Template.kakao.creativeLink.onOff, warning:"소재가 삭제되지 않음. 소재연결 상태가 변경됨"},
                    {value:'KTT2006', label:'삭제', path: Template.kakao.creativeLink.delete, warning:"소재가 삭제되진 않음. 연결된 ID가 삭제됨"},
                ],
            },
            // {
            //     value:'KET0007',
            //     label:'확장소재',
            //     children:[
            //         {value:'KTT2004', label:'ON/OFF 변경', path: Template.kakao.creativeLink, warning:"소재가 삭제되지 않음. 소재연결 상태가 변경됨"},
            //         {value:'KTT2006', label:'삭제', path: Template.kakao.creativeLink, warning:"소재연결ID 삭제 (소재가 삭제되진 않음. 연결된 ID가 삭제됨)"},
            //     ],
            // },

        ];
    };
        /** 대량 다운로드용(템플릿 유지) */
    public downloadTypeTree = class{
        public static naver:Readonly<TaskOptionType[]> = [
            {
                value:'NPT0001',
                label:'파워링크',
                code: NaverCode.data.type.campaign.WEB_SITE.value,
                children:[
                    {
                        value:'NET0003',
                        label:'캠페인',
                        children:[
                            // {value:'NDT0002', label:'등록',},
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0006', label:'수정', },
                        ],
                    },

                    {
                        value:'NET0004',
                        label:'광고그룹',
                        children:[
                            // {value:'NDT0002', label:'등록', path: },
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            {value:'NDT0006', label:'수정', },
                            // {value:'NDT0007', label:'복사', disabled:true, warning:'추후 제공', },
                        ],
                    },
                    {
                        value:'NET0006',
                        label:'키워드',
                        children:[
                            // {value:'NDT0002', label:'등록', },
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            {value:'NDT0007', label:'복사', },
                            {value:'NDT0006', label:'수정', },
                        ],
                    },
                    {
                        value:'NET0005',
                        label:'소재',
                        children:[
                            // {value:'NDT0002', label:'등록', },
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            {value:'NDT0007', label:'복사', },
                        ],
                    },
                    {
                        value:'NET0007',
                        label:'확장소재',
                        children:[
                            // {value:'NDT0002', label:'등록', disabled:true, warning:"추후 제공"},
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            {value:'NDT0007', label:'복사', warning:"파워링크의 확장소재 복사는 '추가제목, 추가설명, 홍보문구, 서브링크, 가격링크'만 가능합니다."},
                        ],
                    },
                    {
                        value:'NET0008',
                        label:'타겟팅',
                        children:[],
                    },
                ],
            },
            {
                value:'NPT0002',
                label:'쇼핑검색',
                code: NaverCode.data.type.campaign.SHOPPING.value,
                children:[
                    {
                        value:'NET0003',
                        label:'캠페인',
                        children:[
                            // {value:'NDT0002', label:'등록', },
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0006', label:'수정', },
                        ],
                    },
                    {
                        value:'NET0004',
                        label:'광고그룹',
                        children:[
                            // {value:'NDT0002', label:'등록', path: Template.naver.shopping.adgroup, warning:<>
                            //     <p>광고그룹유형 3가지 중 '쇼핑몰 상품형'만 등록 제공</p>
                            //     <p>그룹입찰가 : 자동입찰설정 (beta, 동의필요) 제공 안 함</p>
                            // </>},
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            {value:'NDT0006', label:'수정', },
                        ],
                    },
                    {
                        value:'NET0006',
                        label:'키워드',
                        children:[
                            {value:'NDT0004', label:'ON/OFF 변경', },
                        ],
                    },
                    {
                        value:'NET0005',
                        label:'소재',
                        children:[
                            // {value:'NDT0002', label:'등록', path: Template.naver.shopping.ad},
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            {value:'NDT0005', label:'수정(입찰가변경)', },
                            {value:'NDT0007', label:'복사', },
                        ],
                    },
                    {
                        value:'NET0007',
                        label:'확장소재',
                        children:[
                            // {value:'NDT0002', label:'등록', disabled:true, warning:"추후 제공, 제공 확장소재 유형 : 추가홍보문구"},
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0003', label:'삭제', },
                            // {value:'NDT0007', label:'복사', path: },
                        ],
                    },
                    {
                        value:'NET0008',
                        label:'타겟팅',
                        children:[],
                    },

                ],
            },
            {
                value:'NPT0003',
                label:'D-BID',
                code: NaverCode.data.type.campaign.WEB_SITE.value,
                children:[
                    {
                        value:'NET0101',
                        label:'입찰가 조정',
                        children:[
                            {value:'NDT0006', label:'수정', warning:"입찰가(선택) 컬럼에 [제안입찰가]가 입력된 파일을 다운로드 합니다." },
                        ],
                    },
                    {
                        value:'NET0102',
                        label:'키워드 OFF',
                        children:[
                            {value:'NDT0004', label:'ON/OFF 변경', },
                            {value:'NDT0006', label:'수정', },
                        ],
                    },
                ],
            },
        ];

        public static kakao:Readonly<TaskOptionType[]> = [
            {
                value:'KET0003',
                label:'캠페인',
                children:[
                    // {value:'KDT0008', label:'생성', },
                    {value:'KDT0003', label:'ON/OFF 변경', },
                    {value:'KDT0002', label:'수정', },
                ],
            },
            {
                value:'KET0004',
                label:'광고그룹',
                children:[
                    // {value:'KDT0008', label:'생성', warning:"키워드확장 '설정' 선택 시 제외키워드 등록 기능은 제공 안 함 "},
                    {value:'KDT0003', label:'ON/OFF 변경', },
                    {value:'KDT0005', label:'삭제', },
                    {value:'KDT0002', label:'수정', },
                    // {value:'KTT2002', label:'복사', disabled:true, warning:"추후 제공"},
                ],
            },
            {
                value:'KET0005',
                label:'키워드',
                children:[
                    // {value:'KDT0008', label:'생성', },
                    {value:'KDT0003', label:'ON/OFF 변경', },
                    {value:'KDT0005', label:'삭제', },
                    {value:'KDT0002', label:'수정', },
                    {value:'KDT0006', label:'복사', },
                ],
            },
            {
                value:'KET0006',
                label:'소재',
                children:[
                    // {value:'KTT2001', label:'등록 및 연결', disabled:true, p warning:"제공 안 함"},
                    // {value:'KTT2004', label:'ON/OFF 변경', warning:"소재ID 삭제"},
                    {value:'KDT0005', label:'삭제', warning:"소재연결ID가 삭제됨"},
                ],
            },
            {
                value:'KET0007',
                label:'소재연결',
                children:[
                    {value:'KDT0007', label:'소재 연결', disabled:true, warning:"소재와 광고그룹간의 연결정보를 등록합니다."},
                    {value:'KDT0003', label:'ON/OFF 변경', warning:"소재가 삭제되지 않음. 소재연결 상태가 변경됨"},
                    {value:'KDT0005', label:'삭제', warning:"소재가 삭제되진 않음. 연결된 ID가 삭제됨"},
                ],
            },
            // {
            //     value:'KET0007',
            //     label:'확장소재',
            //     children:[
            //         {value:'KTT2004', label:'ON/OFF 변경', warning:"소재가 삭제되지 않음. 소재연결 상태가 변경됨"},
            //         {value:'KTT2006', label:'삭제', warning:"소재연결ID 삭제 (소재가 삭제되진 않음. 연결된 ID가 삭제됨)"},
            //     ],
            // },

        ];
    };

    private storage = class {
        /**
         * 세션 스토리지에 저장합니다.
         * @param token 및 사용자정보
         */
        public static set(data:CodeResType[]){
            const tmp:PublicCodeStorageType = {
                create_time : new Date().getTime(),
                data
            };
            localStorage.setItem(STORAGE_ID, JSON.stringify(tmp));
        }

        /**
         * 세션스토리지의 값을 반환됩니다.
         * @returns 
         */
        public static get():CodeResType[]{
            const data: string | null = localStorage.getItem(STORAGE_ID);
            return data ? JSON.parse(data)?.data : undefined;
        }

        /**
         * 로컬스토리지를 비웁니다.
         */
        public static clear(){
            localStorage.removeItem(STORAGE_ID);
        }
    }

    constructor(){
        this._data = this.storage.get() || [];
        AdminService.code.getList<CodeResType>({}).then((res)=>{
            const data:CodeResType[] = res.data?.result?.sort((a:CodeResType,b:CodeResType)=>(a.code<b.code ? -1 : 1)) || [];
            this.storage.set(data);
            this._data = data;
        });
    }

    public getOptions(category:string|string[]):OptionType[]{
        category = Array.isArray(category) ? category : [category];
        return this._data.filter((v)=>category.includes(v.category)).map((v)=>({value:v.code, label:v.name}));
    }

    public getOption(code?:string):OptionType|undefined{
        const item = this._data.find((v)=>v.code===code);
        return item ? {value:item.code, label:item.name} : undefined;
    }

    public getLabel(code:string):string|undefined{
        return this._data.find((v)=>v.code===code)?.name;
    }

    public formatter(value:string, row?:any, index?:number):string|React.ReactNode|undefined{
        return this.getLabel(value) || value;
    }

    /** 공통코드 데이터로 부터 해당 코드 정보를 반환합니다. */
    public get = (code:string)=>this._data.find((v)=>v.code===code);
}

const PublicCode = new PublicCodeCore();
export default PublicCode;