import {LabelColumnType} from '../LabelTypes';
import {BizChannelResType} from '../kakao/BizChannelTypes';
import {AdResType} from './AdTypes';
import {StatReportResType} from './StatReportType';

export interface AdExtensionType_SUB_LINKS {
    name: string;
    final: string;
}
export interface AdExtensionType_CALCULATION {
    final: string;
}
export interface AdExtensionType_HEADLINE {
    headline: string;
    pin: number;
}
export interface AdExtensionType_DESCRIPTION {
    heading: string;
    description: string;
}
export interface AdExtensionType_DESCRIPTION_EXTRA {
    description: string;
}
export interface AdExtensionType_PRICE_LINKS {
    name: string;
    price: number;
    modifier: string;
    final: string;
}
export interface AdExtensionType_PROMOTION {
    basicText: string;
    additionalText: string;
}
export interface AdExtensionType_POWER_LINK_IMAGE {
    imagePath: string;
}
export interface AdExtensionType_IMAGE_SUB_LINKS {
    name: string;
    final: string;
    imagePath: string;
}
export interface AdExtensionType_CATALOG_RELEASE_DATE {
    releaseDate: string;
}
export interface AdExtensionType_CATALOG_DETAIL {
    detail: string;
}
export interface AdExtensionType_CATALOG_IMAGE {
    imagePath: string;
}
export interface AdExtensionType_CATALOG_PROMOTION {
    basicText: string;
    additionalText: string;
}
export interface AdExtensionType_CATALOG_EVENT {
    dittoId: string;
    thumbnail: string;
}
export interface AdExtensionType_CATALOG_MOVIE {
    dittoId: string;
    thumbnail: string;
}
export interface AdExtensionType_CATALOG_BRAND_MESSAGE {
    text: string;
}
export interface AdExtensionType_NAVER_TV_VIDEO {
    imagePath: string;
    description: string;
    isSponsorReview: boolean;
}
export interface AdExtensionType_NAVER_BLOG_REVIEW {
    title: string;
    postUrl: string;
    thumbnailUrl: string;
}
export type AdExtensionType =
    | AdExtensionType_SUB_LINKS[]
    | AdExtensionType_CALCULATION
    | AdExtensionType_HEADLINE
    | AdExtensionType_DESCRIPTION
    | AdExtensionType_DESCRIPTION_EXTRA
    | AdExtensionType_PRICE_LINKS[]
    | AdExtensionType_PROMOTION
    | AdExtensionType_POWER_LINK_IMAGE
    | AdExtensionType_IMAGE_SUB_LINKS[]
    | AdExtensionType_CATALOG_RELEASE_DATE
    | AdExtensionType_CATALOG_DETAIL
    | AdExtensionType_CATALOG_IMAGE
    | AdExtensionType_CATALOG_PROMOTION
    | AdExtensionType_CATALOG_EVENT
    | AdExtensionType_CATALOG_MOVIE
    | AdExtensionType_CATALOG_BRAND_MESSAGE
    | AdExtensionType_NAVER_TV_VIDEO;

export interface AdExtensionDataType {
    adExtension: AdExtensionType;
    code: string | null;
    title: string | null;
    detail: string | null;
    nccAdExtensionId: string;
    customerId: string;
    ownerId: string;
    pcChannelId: string;
    mobileChannelId: string;
    userLock: boolean;
    type: string;
    usePeriod: boolean;
    schedule: any | null;
    periodStartDt: string | null;
    periodEndDt: string | null;
    status: string;
    statusReason: string;
    referenceData?: AdExtensionType_NAVER_BLOG_REVIEW;
}

export interface AdExtensionResType extends Omit<StatReportResType, 'key'>, LabelColumnType {
    ad?: AdResType;
    adExtensionId: string;
    clientId: number;
    ownerId: string;
    ownerType?: string;
    pcChannelId: string;
    mobileChannelId: string;
    userLock: boolean;
    type: string;
    usePeriod: boolean;
    schedule: any;
    startDate: string;
    endDate: string;
    status: string;
    statusReason: string;
    adExtension: any;
    referenceKey: string;
    referenceData: any;
    regTm: string;
    editTm: string;
    campaignType: string;
    campaignName: string;
    campaignId: string;
    adGroupType: string;
    adGroupName: string;
    adGroupId: string;
    adId: string;
    adType: string;
    hasSchedule: boolean;
    businessInfo: BizChannelResType;
    adExtensionType: string;
    labelValue: number;
    pcChannel: any;
    mobileChannel: any;
}

export enum AdExtensionResKeys {
    labelValue = 'labelValue',
    ad = 'ad',
    adExtensionId = 'adExtensionId',
    clientId = 'clientId',
    ownerId = 'ownerId',
    ownerType = 'ownerType',
    pcChannelId = 'pcChannelId',
    mobileChannelId = 'mobileChannelId',
    userLock = 'userLock',
    type = 'type',
    usePeriod = 'usePeriod',
    schedule = 'schedule',
    startDate = 'startDate',
    endDate = 'endDate',
    status = 'status',
    statusReason = 'statusReason',
    adExtension = 'adExtension',
    referenceKey = 'referenceKey',
    referenceData = 'referenceData',
    regTm = 'regTm',
    editTm = 'editTm',
    campaignType = 'campaignType',
    campaignName = 'campaignName',
    campaignId = 'campaignId',
    adGroupType = 'adGroupType',
    adGroupName = 'adGroupName',
    adGroupId = 'adGroupId',
    adId = 'adId',
    adInfo = 'adInfo',
    adType = 'adType',
    hasSchedule = 'hasSchedule',
}

export const AdExtensionKeys = {...AdExtensionResKeys, lockState: 'lockState'};

export interface AdExtensionPostCopyType {
    targetOwnerIds: string[];
    originalAdExtensionIds: string[];
    userLock: boolean;
}

/** 확장소재 스케쥴 설정값 */
export interface AdExtensionScheduleType {
    MON: number;
    TUE: number;
    WED: number;
    THU: number;
    FRI: number;
    SAT: number;
    SUN: number;
}

/** 확장소재 기본 타입 */
export interface AdExtensionPostBaseType {
    ownerId: string;
    schedule: AdExtensionScheduleType;
    usePeriod: boolean | string;
    startDate: string;
    endDate: string | undefined;
    userLock: boolean | string;
    /** 임시처리용 */
    useSchedule?: boolean | string;
}

export interface AdExtensionPostHeadlineType extends AdExtensionPostBaseType {
    headline: string;
    pin: number;
}
export interface AdExtensionPostDescriptionExtraType extends AdExtensionPostBaseType {
    descriptionExtra: string;
}

export interface AdExtensionPostDescriptionType extends AdExtensionPostBaseType {
    heading: string;
    description: string;
}

export interface AdExtensionPostSublinkInfoType {
    name: string;
    finalUrl: string;
}
export interface AdExtensionPostPricelinkInfoType extends AdExtensionPostSublinkInfoType {
    price: number;
    modifier: string;
}
export interface AdExtensionPostPricelinkType extends AdExtensionPostBaseType {
    priceLinks: AdExtensionPostPricelinkInfoType[];
}

export interface AdExtensionPostSublinkType extends AdExtensionPostBaseType {
    subLinks: AdExtensionPostSublinkInfoType[];
}

export interface AdExtensionPostPromotionType extends AdExtensionPostBaseType {
    basicText: string;
    additionalText: string;
}

export interface AdExtensionPostType {
    headlines?: AdExtensionPostHeadlineType[];
    descriptionExtras?: AdExtensionPostDescriptionExtraType[];
    descriptions?: AdExtensionPostDescriptionType[];
    subLinks?: AdExtensionPostSublinkType[];
    priceLinks?: AdExtensionPostPricelinkType[];
    promotions?: AdExtensionPostPromotionType[];
}

export interface AdExtensionPutType {
    adExtensionIds: string[];
    userLock?: boolean;

    schedule?: AdExtensionScheduleType;
    usePeriod?: boolean | string;
    startDate?: string;
    endDate?: string | undefined;
    /** 임시처리용 */
    useSchedule?: boolean | string;
}

export interface AdExtensionGetCountType {
    campaignId: string;
    campaignName: string;
    adGroupId: string;
    adGroupName: string;
    counts: {
        DESCRIPTION: number;
        HEADLINE: number;
        IMAGE_SUB_LINKS: number;
        NAVER_BLOG_REVIEW: number;
        PHONE: number;
        POWER_LINK_IMAGE: number;
        PRICE_LINKS: number;
        SUB_LINKS: number;
    };
}

export type AdExtensionPostItemType =
    | AdExtensionPostHeadlineType
    | AdExtensionPostDescriptionExtraType
    | AdExtensionPostDescriptionType
    | AdExtensionPostPricelinkType
    | AdExtensionPostSublinkType
    | AdExtensionPostPromotionType;

export enum AdExtensionReqKeys {
    ownerId = 'ownerId',
    schedule = 'schedule',
    useSchedule = 'useSchedule',
    usePeriod = 'usePeriod',
    startDate = 'startDate',
    endDate = 'endDate',
    userLock = 'userLock',
    headlines = 'headlines',
    descriptionExtras = 'descriptionExtras',
    descriptions = 'descriptions',
    subLinks = 'subLinks',
    priceLinks = 'priceLinks',
    promotions = 'promotions',
    headline = 'headline',
    heading = 'heading',
    descriptionExtra = 'descriptionExtra',
    description = 'description',
    name = 'name',
    finalUrl = 'finalUrl',
    basicText = 'basicText',
    price = 'price',
    modifier = 'modifier',
    additionalText = 'additionalText',
    pin = 'pin',

    adExtensionType = 'adExtensionType',
    adExtensionList = 'adExtensionList',
    adGroupList = 'adGroupList',
    possibleRegMaxCnt = 'possibleRegMaxCnt',
    possibleRegCnt = 'possibleRegCnt',

    /** 임시용 - 캠페인/광고그룹 선택창 - 광고그룹 */
    selectAdGroups = 'selectAdGroups',
    /** 임시용 - 캠페인/광고그룹 선택창 - 캠페인 */
    selectCampaigns = 'selectCampaigns',
}
