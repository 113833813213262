import { KakaoMomentServer } from '../../axios';
import Utils from '../../utils/Utils';
import { DatshBoardStatQryType } from '../models/DashboardTypes';



/** 카카오모먼트 대시보드용 */
export default class DashboardHelper{
    static path="/dashboard"; //기본 Path
    /** 카카오모먼트 대시보드 작업이력 정보 */
    public static getStatus(code:string|string[]){
        return Utils.apiRequestValidation({useAccount:false}) || KakaoMomentServer.get(`${this.path}/status`,{params:{status:code}});
    }

    /** 카카오모먼트 대시보드 통계정보 */
    public static getStat(params:DatshBoardStatQryType){
        return Utils.apiRequestValidation({useAccount:false}) || KakaoMomentServer.get(`${this.path}/stat`,{params});
    }
}

