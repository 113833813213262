

/** 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T=StatReportResType, V=StatReportInfoType> {
    info: V;
    performance: T;
}

/**  리포트 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?: StatReportInfoType;
    total?: T[];
    daily?: T[];
    performances?: T[];
}

/** Explorer 상세보기 타입 */
export interface StatReportInfoType{
    customerId: number,
    businessChannelId?: string,
    businessChannelContents?: string,
    businessChannelName?: string,
    businessChannelTypeCode?: number,
    businessChannelTypeName?: string,
    campaignId?: string,
    campaignName?: string,
    campaignTypeCode?: number,
    campaignTypeName?: string,
    adgroupId?: string,
    adgroupName?: string,
    adgroupTypeCode?: number,
    adgroupTypeName?: string,
    adgroupContentType?: string,
    adId?: string,
    adTypeCode?: number,
    adValue1?: string,
    adValue2?: string,
    adValue3?: string,
    adValue4?: string,
    adValue5?: string,
    adValue6?: string,
    keywordId?: string,
    keyword?: string,
    keywordTypeCode?: number,
    keywordTypeName?: string,
    adextensionId?: string,
    adextensionTypeCode?: number,
    adextensionTypeName?: string,
    adextension?: string,
    referenceKey?: string,
    referenceData?: string,
    searchKeyword?: string,
    searchKeywordId?: string,
    mediaCode?: string,
    mediaName?: string,
    ownerId?: string,
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType{
    key: string;
    spend: number;
    impressionCount: number;
    clickCount: number;
    vplayCount: number;
    adCost: number;
    convCount: number;

    cpa: number;
    ctr: number;
    vtr: number;
    cpc: number;
    cpm: number;
    cpv: number;
}

/**
 * 리포트 컬럼 처리용 키
 */
export enum StatReportResKeys{
    key             = "key",
    spend           = "spend",
    impressionCount = "impressionCount",
    clickCount      = "clickCount",
    vplayCount      = "vplayCount",
    adCost          = "adCost",
    convCount       = "convCount",

    cpa             = "cpa",
    ctr             = "ctr",
    vtr             = "vtr",
    cpc             = "cpc",
    cpm             = "cpm",
    cpv             = "cpv",
}



