import clsx from 'clsx';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import AdploButton from '../../../pages/components/buttons/AdploButton';
import { ConfirmProps } from './Confirm';

/** 단순 Alert 형태의 팝업창 */
const ConfirmAlert:React.FC<ConfirmProps> = (props)=>{
    const [show, setShow] = props.state;

    const okClick = (e:React.MouseEvent<HTMLButtonElement>)=>{
        setShow(false);
        props.ok?.onClick && props.ok.onClick(e);
    }
    const cancelClick = (e:React.MouseEvent<HTMLButtonElement>)=>{
        setShow(false);
        props.cancel?.onClick && props.cancel.onClick(e);
    }
    return (
        <Modal show={show} className={props.className} style={props.style} size={props.size}>
            <ModalHeader style={{borderBottom:'none'}}>{props.title}</ModalHeader>
            <ModalBody className='whiteSpace pt-0 pb-0'>{props.children}</ModalBody>
            <ModalFooter className='text-center w-100 justify-content-right' style={{borderTop:'none'}}>
                {props.cancel && (
                    <AdploButton variant={props.cancel.variant || 'secondary'} onClick={cancelClick} 
                        className={clsx('me-1', props.cancel.className)} 
                        style={props.cancel.style}
                    >{props.cancel.title || "Cancel"}</AdploButton>)}
                {props.ok && (
                    <AdploButton variant={props.ok.variant || "primary"} onClick={okClick} 
                        className={clsx(props.ok.className)}
                        style={props.ok.style}
                    >{props.ok.title || "OK"}</AdploButton>)}
            </ModalFooter>
        </Modal> 
    );
}

export default ConfirmAlert;