
import React, { useEffect, useState } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { v4 as uuid } from 'uuid';

 interface SwitchProps extends Omit<FormControlProps, 'id' | 'type'>{
    checked?:boolean;
    checkBox?:boolean;
    text?:string|React.ReactNode;
}
/**
 * 스윗치 타입의 체크박스
 * @param props 
 * @returns 
 */
const Switch = (props:SwitchProps)=>{
    const uid = uuid();
    const [useChecked, setUseChecked] = useState<boolean>(props.checked ? true : false);
    
    const change=(e:React.ChangeEvent<HTMLInputElement>)=>{
        if(props.readOnly && props.readOnly===true){ return; }
        setUseChecked(e.target.checked);
        props.onChange && props.onChange(e);
    }

    useEffect(()=>{ 
        setUseChecked(props.checked||false);
    }, [props.checked]);

    let size="";
    switch(props.size){
        case "sm" : size="h-20px w-30px"; break;
        case "lg" : size="h-40px w-60px"; break;
    }
    // const props_inp = {...props, children:undefined};
    return (<div className={`d-flex ${props.className}`}>
                {props.children && <div className="me-auto">{props.children}</div>}
                <div className="form-check form-switch form-check-custom form-check-solid" style={{display:(props.checkBox ? "" : "none")}}>
                    <FormControl className={`form-check-input  ${size}`} type="checkbox" id={'sw-'+uid} style={{minHeight:"auto", padding:0}} onChange={change} checked={useChecked} disabled={props.disabled} readOnly={props.readOnly} />
                    <label className="form-check-label" htmlFor={'sw-'+uid}></label>
                </div>
            </div>);
}

export default Switch;