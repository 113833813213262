import { CSSProperties } from "react";

/**
 * select, checkbox-group, radio-group 등에서 사용합니다.
 */
export interface OptionType<S=string,T=string>{
    label:T;
    value:S;
    description?:string;
    code?: string;
    order?:number;
    isView?:boolean;
    children?:OptionType<S,T>[];
    index?:number;
    style?:CSSProperties;
    disabled?:boolean;
    warning?:string|React.ReactNode;    // 다운로드 팝업에서 옵션선택에 따라 표시하도록 함
}
export class Option{

    /**
     * @param data object형 데이터를 Option 구조로 변환합니다.
     * @returns OptionType 목록반환
     */
    static objectToOption = (data:object|object[]):OptionType[]|undefined=>{
        let result:OptionType[] = [];
        const data2:object[] = Array.isArray(data) ? data : [data];
        data2.forEach((v)=>{
            const tmp = this.objectToOptionCore(v);
            result = [...result, ...(tmp || [])];
        });
        return result;
    }

    /**
     * 오브젝트형을 OptionType 구조로 변환합니다.
     * @param data 
     * @returns 
     */
    static objectToOptionCore = (data?:object):OptionType[]|undefined=>{
        return data ? Object.entries(data).map((v):OptionType=>{return {value:v[0], label:v[1] as string}}) : undefined;
    }

    static getValue = (options:OptionType[], value:string)=>{
        return options.find((v)=>v.value===value)?.label;
    }
}

export interface ModuleProps<S=any> {
    useConfirm: boolean;
    state:[Map<string,any>, (key:S, value:any) => void];
    isViewer?: boolean;
}
