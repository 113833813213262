/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx';
import {omit} from 'lodash';
import {forwardRef} from 'react';
import {Button} from 'react-bootstrap';
import styles from './style.module.scss';

export interface AdploButtonProps extends React.InputHTMLAttributes<HTMLButtonElement> {
    variant?: string;
    ['data-type']?: 'icon' | 'sm';
    ['data-color']?: 'info' | 'primary' | 'danger';
}

/** AdPlo 기본 버튼 디자인 */

const AdploButton = forwardRef<HTMLButtonElement, AdploButtonProps>((props, ref) => (
    <button
        {...omit(props, 'variant', 'type')}
        ref={ref}
        data-variant={props.variant || 'light'}
        className={clsx('align-content-center border', styles.btn, props.className)}
    >
        {props.children}
    </button>
));

export default AdploButton;

/** AdPlo 기본 버튼 디자인 */
const AdploButtonOld: React.FC<AdploButtonProps> = (props) => {
    let variant: string = 'light';
    let className: string =
        'border border-gray-300 text-dark btn-active-light-primary border-hover-primary text-hover-primary';
    switch (props.variant) {
        case 'primary':
            variant = 'primary';
            className = '';
            break;
        case 'secondary':
            variant = 'secondary';
            className = 'border-secondary';
            break;
    }

    return (
        <Button
            variant={variant}
            data-variant={variant}
            className={clsx('align-content-center border', styles.btn, className, props.className)}
            size='sm'
            style={props.style}
            disabled={props.disabled}
            // onClick={props.onClick}
            // onDoubleClick={props.onDoubleClick}
            title={props.title}
            tabIndex={0}
        >
            {props.children}
        </Button>
    );
};
