
const Config = (() => {
    let mode:string = "local";
    switch(process.env.REACT_APP_BUILD_MODE){
        case "production" : mode="pro"; break;
        case "development" : mode="devel"; break;
        case "stage" : mode="stage"; break;
    }
    return {
        project: {
            name : process.env.REACT_APP_NAME ,
            version : process.env.REACT_APP_VERSION,
            mode: mode,
            node_env: process.env.NODE_ENV,
            path:`${document.location.protocol}//${document.location.host}`,
        },
        session:{
            expire_time: (process.env.REACT_APP_SESSION_EXPIRETIME || '1200').toNumber(), //세션 라이프타임
        },
        api: {
            clientId: process.env.REACT_APP_API_CLIENTID,
            secretKey: process.env.REACT_APP_API_SECRETKEY,
            admin: process.env.REACT_APP_API_ADMIN,
            sso: process.env.REACT_APP_API_SSO,
            portal: process.env.REACT_APP_API_PORTAL,
            sse: process.env.REACT_APP_API_SSE,
            nalo: process.env.REACT_APP_API_NALO,
            kakao: process.env.REACT_APP_API_KAKAO,
            naver: process.env.REACT_APP_API_NAVER,
            gfa: process.env.REACT_APP_API_GFA,
            nosp: process.env.REACT_APP_API_NOSP,
            kakaomoment: process.env.REACT_APP_API_KAKAOMOMENT,
            google: process.env.REACT_APP_API_GOOGLE,
            meta: process.env.REACT_APP_API_META,
            dbid: process.env.REACT_APP_API_NAVER_DBID,
            tools: process.env.REACT_APP_API_TOOLS,
            notification: process.env.REACT_APP_API_NOTIFICATION,
        },
        app: {
            DBid:{ enable: process.env.REACT_APP_APP_DBID === "true", },
            AutoBid:{ enable: process.env.REACT_APP_APP_AUTOBID === "true", },
            TreeMode:{ enable: process.env.REACT_APP_APP_TREEMODE === "true", },
            NALO:{ enable: process.env.REACT_APP_APP_NALO === "true", },
            DA:{ enable: process.env.REACT_APP_APP_DA === "true", },
            Label:{ enable: process.env.REACT_APP_APP_LABEL === "true", },
            SSEM:{ enable: process.env.REACT_APP_APP_SSEM === "true", },
            Notification:{ enable: process.env.REACT_APP_APP_NOTIFICATION === "true", }, 
            ClientSelectV2:{ enable: process.env.REACT_APP_APP_CLIENT_SELECT_v2 === "true", }, 
            NoticeBanner:{ enable: process.env.REACT_APP_APP_NOTICE_BANNER === "true", }, 
        },
        service:{
            portal: process.env.REACT_APP_PORTAL_HOST,
        },
        /** AdPlo 기본 처리 정책 */
        system:{
            /** 수정, 삭제, 복사 등 선택항목 제한 개수 */
            selectLimited: 100,

            /** 비즈머니 */
            bizmoney:{
                /** 데이터 관리 */
                data:{
                    /** 데이터 유효시간 - 동기화 시간 기준 */
                    expire_time: 5 * 50 * 1000, // 5분

                    sync_retry_max_count: 20,   // 재호출 또는 polling 최대 회수
                },

            }
        }
    };
})();

export default Config;

