import clsx from 'clsx';
import { omit } from 'lodash';
import React, { CSSProperties, InputHTMLAttributes, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import styles from './style.module.scss';


const rangeDescription = (props:{unit?:string} & InputHTMLAttributes<HTMLInputElement>)=>{
    let str='';

    if(props.min !==undefined && props.max !== undefined){
        str += `${(props.min || 0).addComma()}${props.unit} ~ ${(props.max || 0).addComma()}${props.unit}까지 입력 가능`;
    }
    else if(props.min !==undefined){
        str += `${(props.min || 0).addComma()}${props.unit}이상 입력 가능`;
    }
    else if(props.min !==undefined){
        str += `${(props.max || 0).addComma()}${props.unit}까지 입력 가능`;
    }
    if(props.step){
        str += `(${(props.step || 0).addComma()}${props.unit} 단위 입력)`;
    }
    return str;
}

export interface InpuBoxProps extends InputHTMLAttributes<HTMLInputElement>{
    unit?:string;
    children?:React.ReactNode;
    showUnit?: boolean;
    showDescription?:boolean;
    titleStyle?:CSSProperties;
    titleClassName?:string;
    layerClassName?:string;
    /**
     * 체크전에 조정하여 체크할 수 있습니다. 글자수 체크
     * @param value 
     * @returns 
     */
    replace?:(value:string)=>string;
}

const InputBox:React.FC<InpuBoxProps> = (props)=>{
    // props = {type:"text", ...props};
    const [value,setValue] = useState<string | number | string[] | readonly string[]>('');
    const onChange=(e:React.ChangeEvent<HTMLInputElement>) =>{
        props.onChange && props.onChange(e);
        setValue(e.target.value);
    }
    useEffect(()=>{setValue(props.value || '')},[props.value]);
    const value2:string = props.replace ? props.replace(value.toString()) : value.toString();
    const inp_props = omit(props, ['children', 'unit', 'showUnit', 'showDescription', 'titleStyle', 'replace', 'layerClassName']);
    const maxLength:number|undefined = props.maxLength ? props.maxLength + (value.toString().length - value2.length) : undefined;
    return (<Form.Group className={clsx('btn-toolbar', styles.input_box, props.layerClassName)} style={{ marginBottom:"0.5em"}}>
        {props.title && (<span className={clsx('pe-2', props.titleClassName)} style={{width:"11em", textAlign:"right", ...props.titleStyle}}>
            {props.title}</span>)}
        <div style={{width:props.type==='text' ? '-webkit-fill-available' : 'inherit'}}>
            <InputGroup>
                <input {...inp_props} maxLength={maxLength} onChange={onChange} className={clsx("form-control py-1", props.className)} 
                    style={{width:"inherit", height:'35px', ...props.style}} value={value} />
                {props.children}
            </InputGroup>
            {props.maxLength && props.type==='text' && (
                <Form.Label className='fs-7 text-muted' style={{display:'inline-block', float:'right'}}>
                    {value2.length}/{props.maxLength}
                </Form.Label>
            )}
        </div>
        {props.showUnit!==false && props.unit && (<Form.Label>{props.unit}</Form.Label>)}
        {props.showDescription!==false && props.unit && (<div className={clsx('fs-7 text-gray-600', styles.notice)}>{rangeDescription(props)}</div>)}
    </Form.Group>);
}

export default InputBox;
