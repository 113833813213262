import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { FormSelect, FormSelectProps } from "react-bootstrap";
import { OptionType } from '../../../../modules/types/Types';
import {omit} from 'lodash';

export interface SelectType extends Omit<FormSelectProps,'type' | 'onChange'> {
    options: OptionType[];
    setValueYn?:boolean;
    onChange?: (value:string) => void;
}

function Select(props:SelectType) {

    const [useValue, setUseValue] = useState<string | number | readonly string[] | undefined>('');

    useEffect(() => {
        setUseValue(props.value);
    }, [props.value]);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUseValue(e.target.value);
        props.onChange && props.onChange(e.target.value);
    }

    const props_int = omit(props, ['onChange', 'options', 'value', 'setValueYn']);
    
    return <> 
        {!props.setValueYn && <FormSelect defaultValue={useValue} onChange={onChange} {...props_int} className={clsx('form-control bg-transparent', props.className)} autoComplete='off'>
        { props.options.map((v, index)=><option key={index} value={v.value}>{v.label}</option>) }
        </FormSelect> }
        {props.setValueYn && <FormSelect value={useValue} onChange={onChange} {...props_int} className={clsx('form-control bg-transparent', props.className)} autoComplete='off'>
        { props.options.map((v, index)=><option key={index} value={v.value}>{v.label}</option>) }
        </FormSelect> }
        </>;
}

export default Select;