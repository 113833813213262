import {AxiosError} from 'axios';
import {MediaResponseType} from './models/ResponseTypes';
export class Service {
    public static ErrorMessage(e: AxiosError<MediaResponseType>) {
        const error: MediaResponseType | undefined = e.response?.data;
        return error?.error?.message || e.message || e;
    }

    public static FilterJsonStringify(data: any) {
        return JSON.stringify(data).replaceAll('"', "'");
    }
}
