import clsx from "clsx";
import { HtmlHTMLAttributes, useState } from "react";
import { Badge, Container } from "react-bootstrap";
import { EstimateUserType } from "../../../../common/services/models/admin/EstimateTypes";
import Code from "../../../../modules/code/Code";
import PublicCode from "../../../../modules/code/PublicCode";
import { Confirm } from "../../../../modules/message";
import { BizMoneyAccountGroupType } from "../../../components/bizmoney/BizMoneyLibs";

interface ColumnUserListProps{
    data: BizMoneyAccountGroupType;
}

const ColumnUserList:React.FC<ColumnUserListProps> = (props)=>{
    const [show, setShow] = useState<boolean>(false);
    const items:EstimateUserType[] = props.data?.accountUsers || [];
    const isOwner:boolean = props.data?.roleAuth === PublicCode.options.role.OWNER.value;

    return <>
        <CustomBadge onClick={items.length>0 && isOwner ? ()=>setShow(true) : undefined}>{items.length}</CustomBadge>
        <Confirm 
            title='사용자 목록' 
            state={[show, setShow]}
            cancel={{onClick:()=>{setShow(false);}, title:'닫기'}}
        >
            <div className="text-gray-400">
                <b>Owner :</b> <span className="text-info me-6">{items.filter((v)=>v.roleAuth===PublicCode.options.role.OWNER.value).length}</span>
                <b>Viewer :</b> <span className="text-info">{items.filter((v)=>v.roleAuth===PublicCode.options.role.VIEWER.value).length}</span>
            </div>
            <div className="scroll mt-6" style={{maxHeight:'300px'}}>
                {items.sort((a,b)=>a.name<b.name ? -1 : 1).map((v,index)=><Container key={index} className="d-flex mt-3 mb-3 p-0">
                    <Container className="p-0" style={{alignSelf:'center'}}>
                        <div>
                            {v.name}
                            <span className="text-gray-600 ms-3"><i className="bi bi-envelope-at me-1"/>{v.email}</span>
                        </div>
                    </Container>
                    <Container className="col-2 ps-0" style={{alignSelf:'center'}}>
                        <Badge className="p-3 ps-4 pe-4" bg={v.roleAuth===PublicCode.options.role.OWNER.value ? "light-primary" : "light-success"}>
                            {Code.getLabel(PublicCode.options.role, v.roleAuth)}
                        </Badge>
                    </Container>
                </Container>)}
            </div>
        </Confirm>
    </>
}

export default ColumnUserList;


const CustomBadge:React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props)=>{
    return <div {...props}
        className={clsx('pt-1 text-center border bg-light-dark rounded',
            props.onClick ? 'cursor-pointer border-hover-primary' : '',
        )}
        style={{
            fontFamily: 'Inter, Helvet, "sans-serif"',
            display:'inline-block',
            width: '26px',
            height: '26px',
            whiteSpace:'nowrap',
            ...props.style,
        }}
    />
}