import clsx from 'clsx';
import {FC} from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import styles from '../../../../../app/pages/modules/modify/modify.module.scss';
import {checkIsActiveNew, KTSVG, WithChildren} from '../../../../helpers';
import {KTIcon} from '../../../../helpers/components/KTIcon';
import {useLayout} from '../../../core';

type Props = {
    to: string;
    title: string;
    icon?: string;
    fontIcon?: string;
    keenIcon?: string;
    hasBullet?: boolean;
    badge?: string;
    className?: string;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
    children,
    to,
    title,
    icon,
    fontIcon,
    keenIcon,
    hasBullet = false,
    badge,
    className,
}) => {
    const {pathname} = useLocation();
    const isActive = checkIsActiveNew(pathname, to);
    const {config} = useLayout();
    const {app} = config;

    return (
        <div className='menu-item'>
            <Link
                className={clsx('menu-link without-sub', className, {active: isActive})}
                to={badge ? '#' : to}
            >
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                    </span>
                )}
                {(icon || fontIcon || keenIcon) && (
                    <span className='menu-icon'>
                        {' '}
                        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                            <KTSVG path={icon} className='svg-icon-2' />
                        )}
                        {fontIcon && ( //app?.sidebar?.default?.menu?.iconType === 'font' && (
                            <i className={clsx('bi fs-3', fontIcon)}></i>
                        )}
                        {keenIcon && <KTIcon iconName={keenIcon} style={{fontSize: '1.5em'}} />}
                    </span>
                )}
                <span className='menu-title'>{title}</span>

                {badge && (
                    <span className={`badge badge-light ${styles.badge} ${styles.badge_light}`}>
                        {badge}
                    </span>
                )}
            </Link>
            {children}
        </div>
    );
};

export {SidebarMenuItem};
