import { StatReportInfoType, StatReportResType } from "../../../common/services/models/gfa/StatReportType";
import GfaCode from "../../../modules/code/GfaCode";
import { ModifyViewerType } from "../../modules/modify/Modify";


export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType,"title">{
    title: string|React.ReactNode;
}

export const convertStatInfoViewData = (level?:string, data?:StatReportInfoType):ConvertStatInfoViewDataType[]|undefined=>{
    switch(level){

        case GfaCode.data.options.ExplorerLevel.campaign.value : {
            return [
                {title:'캠페인 ID', value:data?.campaignId},
                // {title:'캠페인 유형', value: GfaCode.getLabel(GfaCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
            ];
        }
        case GfaCode.data.options.ExplorerLevel.adSet.value : {
            return [
                // {title:'광고그룹명', value:tmp?.adGroupName},
                {title:'광고그룹 ID', value:data?.adgroupId},
                // {title:'광고그룹 유형', value: GfaCode.getLabel(GfaCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                // {title:'캠페인 유형', value: GfaCode.getLabel(GfaCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
            ];
        }

        case GfaCode.data.options.ExplorerLevel.ad.value : {
            return [
                {title:'소재ID', value:data?.adId},
                // {title:'소재 유형', value: GfaCode.getLabel(GfaCode.data.explorer.ad, data?.adTypeCode?.toString() || '')},
                // {title:'소제 설명', value: adInfo?.brandDescription},
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                // {title:'캠페인 유형', value: GfaCode.getLabel(GfaCode.data.explorer.campaign, data?.campaignTypeCode?.toString() || '')},
                {title:'광고그룹명', value:data?.adgroupName},
                {title:'광고그룹ID', value:data?.adId},
                // {title:'광고그룹 유형', value: GfaCode.getLabel(GfaCode.data.explorer.adgroup, data?.adgroupTypeCode?.toString() || '')},
            ];
        }
       
    }
    return [];
}


export const AdTitle:React.FC<{data?:StatReportInfoType}> = (props)=>{
    return <>{props.data?.adValue1 || props.data?.adValue2 || props.data?.adValue3}</>;
}

export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType}> = (props)=>{
    switch(props.level){
        case GfaCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case GfaCode.data.options.ExplorerLevel.adSet.value : return <>광고그룹명 : {props.data?.adgroupName}</>;
        case GfaCode.data.options.ExplorerLevel.ad.value : return <>소재명 : <AdTitle data={props.data}/></>;
    }
    return <>Unknown</>;
}
/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
export const getStatDateFormatter = (txt:string)=>{
    const tmp:string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if(isNaN(new Date(tmp).getTime())){ return txt; }
    return new Date(tmp).format('yyyy-MM-dd');
}


/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data:StatReportResType[]):StatReportResType => {
    const total:StatReportResType = {
        key:'합계',
        spend: 0,
        impressionCount: 0,
        clickCount:0,
        vplayCount: 0,
        adCost: 0,
        convCount: 0,
    
        cpa: 0,
        ctr: 0,
        vtr: 0,
        cpc: 0,
        cpm: 0,
        cpv: 0,

    };

    const entries = Object.entries(total);
    data.forEach((v)=>{
        const tmp:Map<string,number> = new Map(Object.entries(v));
        entries.forEach((v2)=>{
            if(v2[0]==='key'){return; }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return {...total, ...Object.fromEntries(entries)};
}

export const AppendStatReportTotal = (data:StatReportResType[]):StatReportResType[] => {
    const total:StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
}
