import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {Badge, Button, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap';
import Code from '../../../../modules/code/Code';
import PublicCode from '../../../../modules/code/PublicCode';
import {
    BizMoneyAccountClientType,
    BizMoneyAccountGroupType,
    BizMoneyDataType,
} from '../../../components/bizmoney/BizMoneyLibs';
import ColumnUserList from './ColumnUserList';
import styles from './style.module.scss';

interface AccountGroupRowProps {
    data: BizMoneyAccountGroupType;
    show?: boolean;
}
const AccountGroupRow: React.FC<AccountGroupRowProps> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    const item = props.data;
    useEffect(() => {
        setShow(props.show || false);
    }, [props.show]);
    return (
        <>
            <tr key={`mag-${item.magId}`} className={styles['table-title']}>
                <td>
                    {show && (
                        <Button
                            variant='light-danger'
                            className='p-0 ps-1'
                            size='sm'
                            onClick={() => setShow(false)}
                        >
                            <i className='bi bi-dash' />
                        </Button>
                    )}
                    {!show && (
                        <Button
                            variant='secondary'
                            className='p-0 ps-1'
                            size='sm'
                            onClick={() => setShow(true)}
                        >
                            <i className='bi bi-plus' />
                        </Button>
                    )}
                </td>
                <td>{item.magName}</td>
                <td>
                    <Badge
                        className='p-2 ps-4 pe-4'
                        bg={
                            item.roleAuth === PublicCode.options.role.OWNER.value
                                ? 'light-primary'
                                : 'light-success'
                        }
                    >
                        {Code.getLabel(PublicCode.options.role, item.roleAuth)}
                    </Badge>
                </td>
                <td>
                    <ColumnUserList data={item} />
                </td>
                <td>{Code.getLabel(Code.base.useYn, item.magUseYn)}</td>
                <td>{item.clients.length.addComma()}</td>
                <td>
                    <BizMoneyResultValue
                        data={item.result}
                        value={item.result?.total?.addComma() || 0}
                        mag={item}
                        valueFixed={true}
                    />
                </td>
                <td>
                    <BizMoneyResultValue
                        data={item.result}
                        value={item.result?.cash?.addComma() || 0}
                        mag={item}
                        valueFixed={true}
                    />
                </td>
                <td>
                    <BizMoneyResultValue
                        data={item.result}
                        value={item.result?.freeCash?.addComma() || 0}
                        mag={item}
                        valueFixed={true}
                    />
                </td>
            </tr>

            <tr
                className={clsx(styles['child-table-header'], styles['child-table'], {
                    [styles.hidden]: !show,
                })}
            >
                <td></td>
                <td>매체</td>
                <td>자산명</td>
                <td>사용 여부</td>
                <td>잔액 수집 상태</td>
                <td>잔액 수집 완료 일시</td>
                <td>총 잔액</td>
                <td>유상 잔액</td>
                <td>무상 잔액</td>
            </tr>
            {item.clients.map((client) => {
                const biz: BizMoneyDataType | undefined = client.result;
                const use: boolean = client.useYn === Code.base.useYn.Y.value;
                return (
                    <tr
                        key={`client-${client.clientId}`}
                        className={clsx(
                            'text-gray-600',
                            styles['child-table'],
                            styles['child-table-body'],
                            {[styles.hidden]: !show}
                        )}
                    >
                        <td></td>
                        <td>{client.mediaType}</td>
                        <td>{client.name}</td>
                        <td>{Code.getLabel(Code.base.useYn, client.useYn)}</td>
                        <td>
                            {use ? (
                                <BizMoneyResultValue
                                    data={biz}
                                    value={PublicCode.getLabel(biz?.status || '')}
                                    isWarning={true}
                                />
                            ) : (
                                <>중단</>
                            )}
                        </td>
                        <td>
                            {use ? (
                                <BizMoneyResultValue
                                    data={biz}
                                    value={dateFormat(biz?.successSyncDateTime)}
                                    useNull={true}
                                />
                            ) : (
                                <>-</>
                            )}
                        </td>
                        <td>
                            <BizMoneyResultValue
                                data={biz}
                                value={biz?.total?.addComma() || 0}
                                client={client}
                            />
                        </td>
                        <td>
                            <BizMoneyResultValue
                                data={biz}
                                value={biz?.cash?.addComma() || 0}
                                client={client}
                            />
                        </td>
                        <td>
                            <BizMoneyResultValue
                                data={biz}
                                value={biz?.freeCash?.addComma() || 0}
                                client={client}
                            />
                        </td>
                    </tr>
                );
            })}
        </>
    );
};

export default AccountGroupRow;

/** 상태에 따른 총잔액 표시 */
interface BizMoneyResultValueProps {
    data?: BizMoneyDataType;
    value?: any;
    isWarning?: boolean;
    valueFixed?: boolean;
    useNull?: boolean;
    mag?: BizMoneyAccountGroupType;
    client?: BizMoneyAccountClientType;
}
/** 상태에 따른 총잔액 표시 */
export const BizMoneyResultValue: React.FC<BizMoneyResultValueProps> = (props) => {
    if (props.mag?.magUseYn === 'N') {
        return <>미사용 광고주</>;
    }
    if (props.client?.useYn === 'N') {
        return <>미사용 자산</>;
    }
    if (props.data === undefined || props.value === undefined) {
        return <>-</>;
    }
    if (props.data.loaded === false) {
        return <Spinner className='text-gray-500' style={{width: '1em', height: '1em'}} />;
    }
    switch (props.data?.status) {
        // 대기중
        case 'CTS0001':
            break;
        // 진행중
        case 'CTS0002':
            break;
        // 완료
        case 'CTS0003':
            return <>{props.value}</>;
        // 실패
        case 'CTS0004':
            return props.isWarning ? (
                <MessageTooltip {...props} />
            ) : (
                <span className='text-danger'>
                    {props.valueFixed ? props.value : props.useNull ? '-' : '갱신 실패'}
                </span>
            );
    }
    return <>-</>;
};

const MessageTooltip: React.FC<BizMoneyResultValueProps> = (props) => {
    return (
        <OverlayTrigger overlay={<Tooltip placement='auto'>{props.data?.message}</Tooltip>}>
            <span className='text-danger'>
                <i className='bi bi-exclamation-circle text-danger' /> 실패
            </span>
        </OverlayTrigger>
    );
};

// const ValueTooltip:React.FC<BizMoneyResultValueProps> = (props)=>{
//     return <OverlayTrigger overlay={<Tooltip placement='auto'>갱신 실패한 자산이 있습니다!<br/>목록을 확인하세요!</Tooltip>}>
//         <span className="text-danger">{props.valueFixed ? props.value : '갱신 실패'}</span>
//     </OverlayTrigger>;
// }

const dateFormat = (date?: string): string => {
    return date ? new Date(date).format('yyyy.MM.dd HH:mm') : '0000.00.00 00:00';
};
